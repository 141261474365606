/** Class object */
import wsKeywords from './wsKeywords.class.js';

/** Helpers **/
import { callWebservice } from './webserviceCaller';
import { getItem } from './../../services/LocaleStorage';

export default class wsStatus
{
  constructor(
    loaderID,
    device,
    location,
    dispatch,
    selectedInstance,
    callBackFctName = null
  ){
    this.loaderID = loaderID;
    this.device = device;
    this.location = location;
    this.dispatch = dispatch;
    this.selectedInstance = selectedInstance;
    this.callBackFctName = callBackFctName

    // instance wbKeyword object
    this.wsKeywords = new wsKeywords(
      loaderID,
      device,
      location,
      dispatch,
      selectedInstance
    )
  }

  updateStatus = ( entries, statusId, oldStatusId ) => 
  {
    // set entries into array of not
    if( !Array.isArray( entries ) ) entries = [ entries ];

    // add new status
    this.addStatus(
      entries,
      statusId
    );

    // update keywords
    switch ( parseInt( statusId ) ) 
    {
      // Nouveau and Ignoré
      case 1:
      case 4:

        // remove keyword from kwCategories
        this.wsKeywords.remove( entries );

        break;

      // Suivi mots-clés
      case 2:

        // update keyword in kwCategories with all extractions datas  
        if( oldStatusId === 3 )
          this.wsKeywords.update( entries, entries[0], null, 'volume|gsc' ); 

        // add keyword to kwCategories with all extractions datas
        else
          this.wsKeywords.add( entries, [ 'null', 'null', 'null' ], 'volume|gsc' ); 

        break;

      // Suivi marché + mots-clés
      case 3:

        // update keyword in kwCategories with gsc extractions datas only
        if( oldStatusId === 2 )
          this.wsKeywords.update( entries, entries[0], null, 'volume|serp|gsc' ); 
        
        // add keyword to kwCategories with gsc extractions datas only
        else
          this.wsKeywords.add( entries, [ 'null', 'null', 'null' ], 'volume|serp|gsc' ); 

        break;
      default:
        void( 0 );
        break;
    }
  }

  addStatus = (
    entries, 
    statusId
  ) => 
  {
    this.exec(
      entries, 
      statusId,
      'add'
    );
  }
  
  exec = (
    entries,
    statusId,
    actionType
  ) => 
  {
    callWebservice( 
      this.loaderID,        
      actionType + '-' + statusId + '-status',
      actionType + '-status',
      this.dispatch,
      this.selectedInstance,
      {
        entries: entries, 
        statusId: statusId,
        userId: parseInt( getItem( 'userId' ) ),
        device: this.device,
        location: this.location        
      },
      this.callBackFctName !== null ? {
        function: this.callBackFctName
      } : null
    );
  }
}